<template>
    <div v-if="!$store.getters.isMobile" class="modal-images">
        <div class="flex-row">
            <i @click="onClickLeft" class="zmdi zmdi-chevron-left flex-wrap m-r-8" :class="chevronLeft" />
            <div
                @click="$emit('close')"
                class="img-profile p-relative"
                v-lazy:background-image="asOriginal(images[currentIndex])"
            />
            <i @click="onClickRight" class="zmdi zmdi-chevron-right flex-wrap m-l-8" :class="chevronRight" />
        </div>
    </div>
    <div v-else class="modal-images mobile">
        <i @click="$emit('close')" class="zmdi zmdi-close" />

        <Carousel ref="carousel" :per-page="1" :navigate-to="[options.selectedIndex, false]">
            <Slide :key="idx" v-for="(image, idx) in images">
                <div class="img-container">
                    <div @click="$emit('close')" class="img-cover" v-img-cover="asOriginal(image)"></div>
                    <div class="back-gradients" />
                    <div v-if="nicknames != [] && nicknames.length === images.length" class="nickname-text">
                        {{ nicknames[idx] }}
                    </div>
                </div>
            </Slide>
        </Carousel>
    </div>
</template>
<script>
export default {
    name: 'ModalImages',
    props: ['options'],
    computed: {
        images() {
            return this.options.images || []
        },
        nicknames() {
            return this.options.nicknames || []
        },
        chevronLeft() {
            return this.currentIndex <= 0 ? 'o-0' : 'cursor-pointer'
        },
        chevronRight() {
            return this.currentIndex >= this.images.length - 1 ? 'o-0' : 'cursor-pointer'
        },
    },
    data: () => ({
        currentIndex: 0,
    }),
    created() {
        window.addEventListener('keydown', this.onKeydown)
        if (this.options.selectedIndex !== undefined) {
            this.currentIndex = this.options.selectedIndex
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeydown)
    },
    methods: {
        asOriginal(url) {
            return url.replace('/medium/', '/original/')
        },
        onKeydown(event) {
            event.preventDefault()
            if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
                this.onClickLeft()
            }
            if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
                this.onClickRight()
            }
        },
        onClickLeft() {
            if (this.currentIndex > 0) {
                this.currentIndex--
            }
        },
        onClickRight() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++
            }
        },
    },
}
</script>
